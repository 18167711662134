/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import {
  requestOrderRecoverySearch,
  requestOrderRecoveryRecover,
  requestOrderChangeStatus,
} from '$redux/orderRecovery/actions';

import Failed from '$routes/Failed';
import moment from 'moment';

import swal from 'sweetalert2';
import { RedButton } from './Grid.styles';

const dateFormat = 'DD-MM-YYYY';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 5,
    };
  }

  fetchData = (state) => {
    const { dispatch } = this.props;
    const { pageSize, page } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted: [],
      filters: {},
    };
    dispatch(requestOrderRecoverySearch(bindingObject));
    this.setState({ pageSize });
  }

  recoverEnrollment = async (orderReference, status) => {
    const { dispatch } = this.props;

    if (status !== 'CREATED') {
      await swal({
        title: 'Confirmación',
        text: 'No se puede recuperar una matricula que no este en estado CREATED',
        type: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, volver atrás',
      });
    } else {
      const { value } = await swal({
        title: 'Confirmación',
        text: '¿Seguro que quieres generar una matricula nueva a partir de este pago?',
        type: 'warning',
        confirmButtonText: 'Sí, hazlo!',
        confirmButtonColor: '#e05959',
        showCancelButton: true,
        cancelButtonText: 'No, volver atrás',
      });
      if (!value) return;

      dispatch(requestOrderRecoveryRecover({ orderReference }));
    }
  }

  requestChangeStatus = async (orderReference, status) => {
    const { dispatch } = this.props;
    const { value } = await swal({
      title: 'Cambio de estado',
      text: `Esta prematricula está en un estado(${status}) no permitido para su recuperación, quieres cambiar el estado de la matricula a CREATED para poder recuperarla?`,
      type: 'warning',
      confirmButtonText: 'Sí, hazlo!',
      confirmButtonColor: '#e05959',
      showCancelButton: true,
      cancelButtonText: 'No, volver atrás',
    });
    if (!value) return;

    dispatch(requestOrderChangeStatus({ orderReference }));
  }

  render() {
    const {
      loading, pages, data, reload,
    } = this.props;
    const { pageSize } = this.state;

    if (reload) window.location.reload();
    if (!data) return <Failed />;

    return (
      <ReactTable
        topBorder
        columns={[
          {
            Header: 'Order',
            accessor: 'orderReference',
            maxWidth: 96,
          },
          {
            Header: 'OrderId',
            accessor: 'orderId',
            width: 150,
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 150,
          },
          {
            Header: 'Total',
            accessor: 'totalCost',
            width: 150,
          },
          {
            Header: 'Curso',
            accessor: 'courseName',
            width: 150,
          },
          {
            Header: 'Tipo de pago',
            accessor: 'paymentMethod',
            width: 150,
            Cell: ({ value }) => {
              const paymentTypes = {
                1: 'Pago con tarjeta',
                2: 'Pago con tarjeta fraccionado',
                3: 'Pago por Aplazame',
              };
              return paymentTypes[!value ? 0 : value];
            },
            maxWidth: 70,
          },
          {
            Header: 'Fecha inicio',
            accessor: 'createdAt',
            Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
            maxWidth: 100,
          },
          {
            Header: 'Usuario',
            accessor: 'user',
            maxWidth: 150,
          },
          {
            // separator
            resizable: false,
          },
          {
            Header: 'Acciones',
            fixed: 'right',
            Cell: row => (
              row.original.status === 'CREATED' ? (
                <RedButton onClick={() => this.recoverEnrollment(row.original.orderReference, row.original.status)}>
                  Recuperar
                </RedButton>
              ) : (
                <RedButton onClick={() => this.requestChangeStatus(row.original.orderReference, row.original.status)}>
                  Cambiar estado
                </RedButton>
              )
            ),
            resizable: false,
            sortable: false,
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={this.fetchData} // Request new data when things change
      />
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool,
  reload: PropTypes.bool,
};

Grid.defaultProps = {
  data: [],
  pages: 0,
  loading: true,
  reload: false,
};

export default connect(
  state => ({
    data: state.orderRecovery.data,
    pages: state.orderRecovery.pages,
    loading: state.orderRecovery.loading,
    reload: state.orderRecovery.reload,
  }),
)(Grid);
