export const REQUEST_ORDER_RECOVERY_SEARCH = 'CANDIDATE_SEARCH_REQUEST';
export const REQUEST_ORDER_RECOVERY_SEARCH_SUCCEEDED = 'CANDIDATE_SEARCH_REQUEST_SUCCEEDED';
export const REQUEST_ORDER_RECOVERY_SEARCH_FAILED = 'CANDIDATE_SEARCH_REQUEST_FAILED';

export const REQUEST_ORDER_RECOVERY_RECOVER = 'REQUEST_ORDER_RECOVERY_RECOVER';
export const REQUEST_ORDER_RECOVERY_RECOVER_SUCCEEDED = 'REQUEST_ORDER_RECOVERY_RECOVER_SUCCEEDED';
export const REQUEST_ORDER_RECOVERY_RECOVER_FAILED = 'REQUEST_ORDER_RECOVERY_RECOVER_FAILED';

export const REQUEST_ORDER_RECOVERY_CHANGE_STATUS = 'REQUEST_ORDER_RECOVERY_CHANGE_STATUS';
export const REQUEST_ORDER_RECOVERY_CHANGE_STATUS_SUCCEEDED = 'REQUEST_ORDER_RECOVERY_CHANGE_STATUS_SUCCEEDED';
export const REQUEST_ORDER_RECOVERY_CHANGE_STATUS_FAILED = 'REQUEST_ORDER_RECOVERY_CHANGE_STATUS_FAILED';
